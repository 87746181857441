<!-- 点击某个项目或空间进入到此项目或空间的详情 -->
<template>
  <div class="project_detail">
    <div class="project_detail_sidebar">
      <h1 :title="projectName" class="project_name text_elip">{{ projectName }}</h1>
      <div class="menu">
        <div class="submenu"
          v-for="menuItem in menuList"
          :key="menuItem.title">
          <h1 class="submenu_title">{{ menuItem.title }}</h1>
          <router-link
            tag="div"
            v-for="submenuItem in menuItem.submenu"
            :key="submenuItem.title"
            :to="{
              path: submenuItem.path,
              query: {
                projectId,
                projectName: encodeURIComponent(projectName),
              }
            }"
            :class="['submenu_item', { 'active' : submenuItem.path === activePath}]">
            {{ submenuItem.title }}
          </router-link>
        </div>
      </div>
    </div>

    <!-- 在此传入项目id -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 菜单列表
      menuList: [
        {
          title: '图表管理',
          submenu: [
            {
              title: '图表分组',
              path: '/project-detail/chart-group'
            },
            {
              title: '图表类型',
              path: '/project-detail/type-group'
            }
          ]
        },
        {
          title: '数据源管理',
          submenu: [
            {
              title: '数据源',
              path: '/project-detail/data-source'
            }
          ]
        },
        {
          title: 'SQL工具箱',
          submenu: [
            {
              title: 'SQL编辑器',
              path: '/project-detail/sql-editor'
            },
            {
              title: 'SQL查询库',
              path: '/project-detail/sql-query'
            }
          ]
        }
      ]
    }
  },
  computed: {
    // 项目id
    projectId () {
      return this.$route.query.projectId || ''
    },
    // 项目标题
    projectName () {
      return decodeURIComponent(this.$route.query.projectName) || ''
    },

    // 激活的title
    activePath () {
      const curPath = this.$route.path
      const listSource = this.$route.query.listSource || ''

      if (curPath === '/project-detail/chart-list') {
        if (listSource === 'chart-group') {
          return this.menuList[0].submenu[0].path
        } else {
          return this.menuList[0].submenu[1].path
        }
      } else {
        return curPath
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .project_detail {
    width: 100%;
    height: 100%;
    display: flex;
    .project_detail_sidebar {
      flex: none;
      width:200px;
      height: 100%;
      background:#18222D;
      padding: 10px;
      .project_name {
        color: #6AEBFF;
        line-height: 40px;
        font-size: 16px;
        font-weight:bold;
        margin-bottom: 10px;
      }
      .menu {
        .submenu {
          .submenu_title {
            font-size: 12px;
            color: #CED8E3;
            line-height: 40px;
          }
          .submenu_item {
            width: 180px;
            height: 36px;
            line-height: 36px;
            padding: 0 10px;
            margin-bottom: 10px;
            color: white;
            border-radius: 2px;
            cursor: pointer;
            &:hover {
              background-color: #424C57;
            }
            &.active {
              background-color: #424C57;
            }
          }
        }
      }
    }
  }
</style>
